import { BodyText, HeadingText } from 'components/common/Typography';
import { Asset } from 'components/contentful/Asset';
import { makeStyles } from 'lib/makeStyles';
import { UIComponentProps } from 'types/contentful';

type Props = UIComponentProps;

const useStyles = makeStyles({
  outerWrapper: ['container mx-auto py-16 sm:py-20 px-6 lg:px-8'],
  assetMapOuterWrapper: ['flow-root mt-12 lg:mt-10'],
  assetMapInnerWrapper: ['-mt-4 flex flex-wrap justify-center lg:-ml-4'],
  assetWrapper: ['mt-8 flex m-4 lg:grow-0 md:mx-6 lg:mx-8'],
  asset: ['h-10'],
  subtext: 'mt-10'
});

const HomepageSectionUsedBy: React.FC<Props> = ({ entry, items }) => {
  const [heading, subtext] = entry.richTextSections;
  const images = items.get('Image');
  const styles = useStyles();

  return (
    <div className={styles.outerWrapper}>
      <HeadingText size="MD" as="h2" align="center">
        {heading.primaryText}
      </HeadingText>

      <div className={styles.assetMapOuterWrapper}>
        <div className={styles.assetMapInnerWrapper}>
          {images.map((image) => (
            <div className={styles.assetWrapper} key={image.id}>
              <Asset
                rawImgTag
                entry={image}
                className={styles.asset}
                ariaHidden={false}
                alt={image.primaryText}
              />
            </div>
          ))}
        </div>
        {subtext && (
          <BodyText bold className={styles.subtext} align="center">
            {subtext.primaryText}
          </BodyText>
        )}
      </div>
    </div>
  );
};

export { HomepageSectionUsedBy };
